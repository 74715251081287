/**
 * Direttiva che costruisce la card con il riassunto della sezione di un progetto
 */

import { SenecaResponse, ItemFrontEndWrapper, Survey, Item } from "atfcore-commonclasses";

export interface ISectionProjectDirectiveScope extends ng.IScope {
	card: any;
	windowWidth: number;
	isWidthExceeded: boolean;
	percentageItem: any;
	suggestedFromAdmin: any;
	globalApplicationData: any;
	libraryApplicationData: any;
	item: any;
	forceToggleIndicator: boolean;
	isItemAlreadyCertified: boolean;
	totalViewedObjInSection: number;
	totalCertifiedObjInSection: number;
	totalWithAnyActionObjInSection: number;
	page: string;
	numberOfChilds: number;
	isSurveyStarted: boolean;
	isSurveyCertificable: boolean;
	totalDisabledObjInSection: number;
	isMandatory: boolean;
	searchMandatoryIn: Array<any>;
	mandatory: number;
	changeMandatory: Function;
	propedeuticReferenceId: string;
	searchPropedeuticReferenceIdIn: Array<any>;
	isPropedeutic: boolean;
	hasPrerequisites: boolean;
	changePrerequisites: Function;
	propedeuticReferenceTitle: Function;
	propedeuticTitle: string;
	setPropedeuticOffCb: Function;
	prerequisites: any;
	propedeuticReferenceIds: Array<string>;
	checkPropedeuticReferenceIds: Function;
	itemChildId: string;
	isItemDisabled: any;
	fullItem: any;
	adminMode: any;
	teacherMode: any;
	currentProject: any;
	sectionList: Array<any>;
	goToItemDetail: Function;
	isConsumed: boolean;
	isItemCertifiable: boolean;
	showPropedeuticalItemToThisChild: Function;
	mandatoryItemChilds: number;
	consumedMandatorItemChilds: number;
	totalConsumedItemChilds: number;
	forceDisable: boolean;
}
angular.module('app').directive("sectionProject", ($window, $state, LibraryApplicationData, GlobalApplicationData, $rootScope, $translate, toaster, blockUI, SurveyService, ProjectService, $stateParams, $sessionStorage, moment, $mdDialog) => {
	return {
		restrict: 'E',
		transclude: true,
		scope: {
			item: '=', // Oggetto contenente tutte le informazioni della riga che sto andando a costruire
			fullItem: '=', // Oggetto intero, contiene non solo il childObject ma anche tutte le sue info (propedeuticReferenceId ecc)
			selectedItemList: "=", // bind sull'array che conterrà le righe selezionate
			forceToggleIndicator: '=', // Toggle indicatore di default (open, consumed, percentage...) / suggeritore
			page: '@',
			isLearningPlanEditingMode: '@',
			openItemDetail: '&',
			suggestedPersonExists: '&',
			toggleSuggestedPerson: '&',
			itemChildsExists: '&',
			toggleItemChilds: '&',
			totalSections: '@',
			currentSectionIndex: '@',
			mandatory: '=?', // obbligatorietà
			searchMandatoryIn: '=', // se sono nel dettaglio del progetto, devo trovare l'obbligatorierà a mano
			propedeuticReferenceId: '=?', // id dell'oggetto propedeutico per quello attuale
			searchPropedeuticReferenceIdIn: '=', // se sono nel dettaglio del progetto, devo trovare la propedeuticità a mano
			propedeuticReferenceTitle: '&', // Titolo della referenza propedeutica
			managePrerequisites: '=',
			propedeuticReferenceIds: '=', // lista dei propedeuticReferenceIds di ogni itemChilds 
			showPropedeuticalItemToThis: '&', // apre una modale per indicare quale oggetto è propedeutico per sbloccare quello corrente
			currentProject: '=',
			sectionList: '=',
			forceDisable: '='
		},
		link: link,
		templateUrl: 'app/shared/sectionProject/sectionProject.html'
	};
	function link($scope: ISectionProjectDirectiveScope, element: JQuery, attrs: ng.IAttributes) {
		// Di base la sezione è abilitata
		$scope.isItemDisabled = false;

		// Verifica se sono nella parte amministrativa della Library
		$scope.adminMode = $sessionStorage.adminMode;
		$scope.teacherMode = $sessionStorage.teacherMode;

		// Verifica se la sezione è consumata
		$scope.isConsumed = false;

		// Elementi della sezione obbligatori
		$scope.mandatoryItemChilds = 0;
		$scope.consumedMandatorItemChilds = 0;

		// Oggetti totali completati
		$scope.totalConsumedItemChilds = 0;

		// Controlla se la sezione è verificata
		$scope.isItemAlreadyCertified = false;

		// Controlla se c'è una verifica iniziata 
		$scope.isSurveyStarted = false;

		// Verifica se c'è una survey disponibile
		$scope.isSurveyCertificable = false;

		$scope.isItemCertifiable = false;

		// Salvo l'obbligatorietà
		$scope.isMandatory = !!$scope.mandatory;
		// o, qualora fossi nel dettaglio del progetto, la recupero
		if ($scope.searchMandatoryIn) {
			for (let i = 0; i < $scope.searchMandatoryIn.length; i++) {
				if ($scope.searchMandatoryIn[i].referenceId == $scope.fullItem.itemId) {
					$scope.isMandatory = !!$scope.searchMandatoryIn[i].mandatory;
					break;
				}
			}
		}

		// Se necessario ricerco la propedeuticità
		if ($scope.searchPropedeuticReferenceIdIn) {
			for (let i = 0; i < $scope.searchPropedeuticReferenceIdIn.length; i++) {
				if ($scope.searchPropedeuticReferenceIdIn[i].referenceId == $scope.fullItem.itemId && $scope.searchPropedeuticReferenceIdIn[i].propedeuticReferenceId) {
					$scope.propedeuticReferenceId = $scope.searchPropedeuticReferenceIdIn[i].propedeuticReferenceId;
					break;
				}
			}
		}

		// Verifico se questo oggetto è propedeutico per qualche altro oggetto
		$scope.isPropedeutic = false;
		$scope.checkPropedeuticReferenceIds = () => {
			for (let m = 0; m < $scope.propedeuticReferenceIds.length; m++) {
				if ($scope.propedeuticReferenceIds[m] == $scope.fullItem.referenceId || $scope.propedeuticReferenceIds[m] == $scope.fullItem.itemId) {
					$scope.isPropedeutic = true;
					break;
				} else {
					$scope.isPropedeutic = false;
				}
			}
		}
		if ($scope.propedeuticReferenceIds) {
			$scope.checkPropedeuticReferenceIds();
		}

		// Verifico se esiste un altro oggetto propedeutico per quello attuale
		$scope.prerequisites = {
			hasPrerequisites: $scope.propedeuticReferenceId ? true : false
		}

		// Callback che, se ho chiuso la modale prima di selezionare un oggetto a cui aggiugnere la propedeuiticità, spenge lo switch
		$scope.setPropedeuticOffCb = () => {
			$scope.prerequisites.hasPrerequisites = false;
		}

		// Se ho un item propedeutico a quello attuale, recupero il suo titolo
		if ($scope.prerequisites.hasPrerequisites) {
			$scope.propedeuticTitle = $scope.propedeuticReferenceTitle($scope.fullItem);
		}

		// Quando cambia l'oggetto propedeutico di quello attuale, devo recuperare il suo titolo
		$scope.$watch('propedeuticReferenceId', (newValue, oldValue) => {
			if (newValue) {
				$scope.propedeuticTitle = $scope.propedeuticReferenceTitle($scope.fullItem);
			}
		}, true);

		// Quando cambia la lista degli oggetti propedeutici, devo aggiornare le etichette di obbligatorietà poichè l'item attuale potrebbe essere diventato
		// un item propedeutico a qualcos'altro
		$scope.$watch('propedeuticReferenceIds', (newValue, oldValue) => {
			if (newValue) {
				$scope.checkPropedeuticReferenceIds();
			}
		}, true);

		// Il mandatory a db è un numero (0 / 1), ma all'md-switch serve un boolean. Così devo gestire manualmente lo switch
		$scope.changeMandatory = () => {
			if ($scope.isMandatory == true) {
				$scope.mandatory = 0;
				$scope.isMandatory = false;
			} else {
				$scope.mandatory = 1;
				$scope.isMandatory = true;
			}
		}

		// Totale oggetti visti della sezione del progetto
		$scope.totalViewedObjInSection = 0;

		// Totale oggetti certificati della sezione del progetto
		$scope.totalCertifiedObjInSection = 0;

		// Totale oggetti con il pallino vuoto (nessuna azione è stata fatta) della sezione del progetto
		$scope.totalWithAnyActionObjInSection = 0;

		// Totale oggetti con il pallino bloccato
		$scope.totalDisabledObjInSection = 0;

		// Oggetto iniziato
		$scope.percentageItem = 0;

		// Suggerito dall'amminsitrazione
		$scope.suggestedFromAdmin = false;

		// Collegamento all'oggetto globale
		$scope.globalApplicationData = GlobalApplicationData;

		// Porta alla pagina di dettaglio di un oggetto aggiunto alla sezione
		$scope.goToItemDetail = (itemChild: any) => {
			if (itemChild.isItemDisabled || $scope.isItemDisabled) {
				$scope.showPropedeuticalItemToThisChild(itemChild.childObject);
			} else {
				if (itemChild && itemChild.referenceId && $scope.item.itemId && $stateParams.itemId) {
					$state.go("app.libraryApp.itemDetailSec", { projectId: $stateParams.itemId, sectionId: $scope.item.itemId, itemId: itemChild.referenceId });
				}
			}
		}

		// Collegamento all'oggetto principale
		$scope.libraryApplicationData = LibraryApplicationData;

		// Apre una modale che indica quale elemneto è propedeutico per aprire quello selezionato
		$scope.showPropedeuticalItemToThisChild = (selectedItem: any) => {
			if ($scope.forceDisable) {
				return;
			}

			$mdDialog.show({
				controller: this.ShowPropedeuticalItemToThisChildController,
				templateUrl: 'showPropedeuticalItemToThis.html',
				parent: angular.element(document.body),
				clickOutsideToClose: true,
				locals: {
					selectedItem: selectedItem,
					item: $scope.item
				}
			})
				.then(() => {
				}, () => {
					// In caso di dismiss non faccio niente
				});
		}

		// Controller per la modale che indica qualche item è obbligatorio per sbloccare quello selezionato
		this.ShowPropedeuticalItemToThisChildController = ($scope: any, $mdDialog: any, selectedItem: any, item: any) => {
			$scope.item = item;

			// Recupero le informazioni complete dell'item selezionato
			$scope.selectedItemFullData = null;
			for (let i = 0; i < $scope.item.itemChilds.length; i++) {
				for (let i = 0; i < $scope.item.itemChilds.length; i++) {
					if ($scope.item.itemChilds[i].referenceId == selectedItem.itemId) {
						$scope.selectedItemFullData = $scope.item.itemChilds[i];
					}
				}
			}
			// Item propedeutico
			$scope.propedeuticalObject = null;

			// Cerco il primo oggetto propedeutico
			for (let i = 0; i < $scope.item.itemChilds.length; i++) {
				if ($scope.item.itemChilds[i].referenceId == $scope.selectedItemFullData.propedeuticReferenceId) {
					// Verifico se questo oggetto è già stato consumato
					let check = $scope.item.itemChilds[i];
					let consumed = false;
					if (check && check.childObject && check.childObject.engagements) {
						for (let k = 0; k < check.childObject.engagements.length; k++) {
							if (check.childObject.engagements[k].eventName == LibraryApplicationData.constants.EVENT_ITEM_CONSUMED) {
								consumed = true;
								break;
							}
						}
					}
					if (!consumed) {
						$scope.propedeuticalObject = $scope.item.itemChilds[i];
						break;
					}
				}
			}

			// Funzione che cerca finchè non trova l'item propedeutico in cima alla lista
			$scope.searchPropedeuticalObject = () => {
				for (let i = 0; i < $scope.item.itemChilds.length; i++) {
					if ($scope.item.itemChilds[i].referenceId == $scope.propedeuticalObject.propedeuticReferenceId) {
						// Verifico se questo oggetto è già stato consumato
						let check = $scope.item.itemChilds[i];
						let consumed = false;
						if (check && check.childObject && check.childObject.engagements) {
							for (let k = 0; k < check.childObject.engagements.length; k++) {
								if (check.childObject.engagements[k].eventName == LibraryApplicationData.constants.EVENT_ITEM_CONSUMED) {
									consumed = true;
									break;
								}
							}
						}
						if (!consumed) {
							$scope.propedeuticalObject = $scope.item.itemChilds[i];
							// se per questo elemento è necessario sbloccarne un altro, lo cerco
							if ($scope.propedeuticalObject.propedeuticReferenceId) {
								return $scope.searchPropedeuticalObject();
							}
							break;
						}
					}
				}
			}

			// Se il mio item propedeutico possiede un altro item propedeutico, lo cerco
			if ($scope.propedeuticalObject && $scope.propedeuticalObject.propedeuticReferenceId) {
				$scope.searchPropedeuticalObject();
			}

			// Chiude la modale
			$scope.cancel = () => {
				$mdDialog.cancel();
			};
		}

		// Se sono nella pagina di dettaglio del progetto, devo caricare gli oggetti associati alla sezione
		if ($scope.page == 'projectDetail') {
			$scope.numberOfChilds = $scope.item.itemChilds.length;

			// Recupero le informazioni sugli oggetti aggiunti alla sezione
			if ($scope.item.itemChilds && $scope.item.itemChilds.length) {
				let propedeuticReferenceIds: any = [];
				for (let j = 0; j < $scope.item.itemChilds.length; j++) {
					let currentItemChild = $scope.item.itemChilds[j];
					currentItemChild.isNotDisabled = false;

					// Se sono un amministratore o docente non serve che io esegua nessun controllo, tutti gli item si devono vedere disabilitati
					if ($scope.adminMode || $scope.teacherMode) {
						currentItemChild.isNotDisabled = true;
						currentItemChild.isItemDisabled = false;
					} else {
						if (currentItemChild.childObject) {
							// Se ha un oggetto propedeutico, lo cerco
							if (currentItemChild.propedeuticReferenceId) {
								for (let p = 0; p < $scope.item.itemChilds.length; p++) {
									if ($scope.item.itemChilds[p].childObject && $scope.item.itemChilds[p].referenceId == currentItemChild.propedeuticReferenceId && $scope.item.itemChilds[p].childObject.engagements && $scope.item.itemChilds[p].childObject.engagements.length) {
										for (let j = 0; j < $scope.item.itemChilds[p].childObject.engagements.length; j++) {
											if ($scope.item.itemChilds[p].childObject.engagements[j].eventName == LibraryApplicationData.constants.EVENT_ITEM_CONSUMED) {
												// Se l'oggetto associato è stato consumato, l'oggetto con il propedeuticReferenceId è sbloccato
												currentItemChild.isNotDisabled = true;
												break;
											}
										}
									}
								}
							}
						}

						if ($scope.forceDisable || (currentItemChild.propedeuticReferenceId && !currentItemChild.isNotDisabled)) {
							// Disabilito l'item corrente se ce n'è un'altro obbligatorio
							currentItemChild.isItemDisabled = true;
						}
					}

					if (currentItemChild.propedeuticReferenceId) {
						propedeuticReferenceIds.push(currentItemChild.propedeuticReferenceId);
					}

					// Verifico se è un oggetto scorm
					currentItemChild.isScorm = currentItemChild && currentItemChild.childObject && currentItemChild.childObject.itemType && (currentItemChild.childObject.itemType == LibraryApplicationData.constants.SCORM_FREE || currentItemChild.childObject.itemType == LibraryApplicationData.constants.SCORM_INVITE) ? true : false;

					// Sottotipo
					currentItemChild.subtype = null;

					// Verifico glia ttributi per recueprare la tipologia di oggetto e le ore formative
					if (currentItemChild.childObject && currentItemChild.childObject.itemAttributes) {
						for (let k = 0; k < currentItemChild.childObject.itemAttributes.length; k++) {
							let currentAttribute = currentItemChild.childObject.itemAttributes[k];

							// Verifico il tipo di oggetto
							if (currentAttribute.attributeType == LibraryApplicationData.constants.OBJECT_TYPE) {
								currentItemChild.itemType = currentAttribute.attributeValue;
							}

							// Verifico se ha il Sottotipo
							if (currentAttribute.attributeType == LibraryApplicationData.constants.OBJECT_TYPE_SPECIALIZATION) {
								currentItemChild.subtype = currentAttribute.attributeValue;
							}


							// Verifico se c'è il valore formativo
							if (currentAttribute.attributeType == LibraryApplicationData.constants.VALUE) {
								let duration = moment.duration(parseInt(currentAttribute.attributeValue), 'seconds');
								currentItemChild.hourValue = {
									hours: duration.minutes(),
									minutes: duration.seconds()
								}
							}
						}
					}

					if (!currentItemChild.subtype) {
						if (currentItemChild.itemType) {
							currentItemChild.subtype = currentItemChild.itemType;
						} else if (!currentItemChild.itemType && currentItemChild.isScorm) {
							currentItemChild.subtype = LibraryApplicationData.constants.SCORM;
						}
					}

					// Verifico gli engagement, cioè le azioni dell'utente sull'Item corrente per ricavare la percentuale
					if (currentItemChild.childObject && currentItemChild.childObject.engagements) {
						// Data dell'evento EVENT_ITEM_STARTED
						let dateEventItemStarted: any = null;
						// Data dell'evento EVENT_ITEM_CONSUMED
						let dateEventItemConsumed: any = null;
						// Percentuale di avanzamento, cioè quella fornita dall'EVENT_ITEM_STARTED
						let percentageEventItemStarted: any = null;

						// Se possiede la scormRegistration, prendo da qui la percentuale di avanzamento
						let hasChildScormRegistration: boolean = currentItemChild.scormRegistration ? true : false;
						if (hasChildScormRegistration) {
							currentItemChild.percentageItem = currentItemChild.scormRegistration.score;
						}

						// Se possiede l'itemRegistration (quindi non è uno scorm ma è un oggetto Kaltura), prendo da qui la percentuale di avanzamento
						let hasChildItemRegistration: boolean = currentItemChild.childObject && currentItemChild.childObject.itemRegistration ? true : false;
						if (hasChildItemRegistration) {
							currentItemChild.percentageItem = currentItemChild.childObject.itemRegistration.currentPrecentage;
						}

						// Inizio a scorrermi tutti gli engagement
						for (let d = 0; d < currentItemChild.childObject.engagements.length; d++) {
							let currentEngagement = currentItemChild.childObject.engagements[d];

							if (currentEngagement.moduleName == LibraryApplicationData.constants.ITEM) {
								// Attualmente, la percentuale di avanzamento (o percentuale degli oggetti) è data dalla percentuale più recente fra quella dell'item consumed e quella dell'item started. Quindi la più recente, va mostrata. Di conseguenza, devo recuperarle entrambe e, poi, confrontarle
								if (currentEngagement.eventName == LibraryApplicationData.constants.EVENT_ITEM_CONSUMED) {
									// Item concluso. Salvo la data in cui è stato creato tale engagement
									dateEventItemConsumed = currentEngagement.creationDate;
									// L'item è stato concluso
									currentItemChild.isConsumed = true;
								}

								if (currentEngagement.eventName == LibraryApplicationData.constants.EVENT_ITEM_STARTED && currentEngagement.engagementDetails) {
									// Item iniziato. Salvo la data in cui è stato creato tale engagement e la percentuale
									dateEventItemStarted = currentEngagement.creationDate;
									for (let z = 0; z < currentEngagement.engagementDetails.length; z++) {
										let currentEngagementDetail = currentEngagement.engagementDetails[z];
										if (!hasChildItemRegistration && !hasChildScormRegistration && currentEngagementDetail.detailKey == LibraryApplicationData.constants.PERCENTAGE) {
											percentageEventItemStarted = currentEngagementDetail.detailValue;
											if (isNaN(percentageEventItemStarted)) {
												percentageEventItemStarted = "0";
											}
											break;
										}
									}
								}

								// Verifico se l'oggetto è già stato verificato
								if (currentEngagement.eventName == LibraryApplicationData.constants.EVENT_ITEM_CERTIFIED) {
									currentItemChild.isItemAlreadyCertified = true;
								}
							}
						}

						// Se ho sia l'engagement STARTED che CONSUMED, devo recuperare il più recente
						if (!hasChildScormRegistration && !hasChildItemRegistration) {
							if (dateEventItemStarted && dateEventItemConsumed) {
								let startedTime = new Date(dateEventItemStarted).getTime();
								let consumedTime = new Date(dateEventItemConsumed).getTime();
								if (startedTime > consumedTime) {
									// E' più recente l'evento started, quindi visualizzo a front-end la sua percentuale
									currentItemChild.percentageItem = percentageEventItemStarted;
								} else {
									// Altrimenti la setto io al 100%
									currentItemChild.percentageItem = "100";
								}
							} else if (dateEventItemStarted && !dateEventItemConsumed) {
								currentItemChild.percentageItem = percentageEventItemStarted;
							} else if (!dateEventItemStarted && dateEventItemConsumed) {
								currentItemChild.percentageItem = "100";
							} else {
								currentItemChild.percentageItem = "0";
							}
						}
					}
				}

				if (propedeuticReferenceIds.length) {
					for (let j = 0; j < $scope.item.itemChilds.length; j++) {
						let currentItemChild = $scope.item.itemChilds[j];

						currentItemChild.isPropedeutic = false;
						let checkPropedeuticReferenceIds = () => {
							for (let m = 0; m < propedeuticReferenceIds.length; m++) {
								if (propedeuticReferenceIds[m] == currentItemChild.referenceId) {
									currentItemChild.isPropedeutic = true;
									break;
								} else {
									currentItemChild.isPropedeutic = false;
								}
							}
						}
						if (propedeuticReferenceIds) {
							checkPropedeuticReferenceIds();
						}

					}
				}

				// Qualora gli Item di questi referenceId fossero già stati completati, li rimuovo
				if (!$scope.adminMode && !$scope.teacherMode && ($scope.forceDisable || ($scope.propedeuticReferenceIds && $scope.propedeuticReferenceIds.length && $scope.sectionList && $scope.sectionList.length && $scope.propedeuticReferenceId))) {
					$scope.isItemDisabled = true;
					let mandatoryItems = 0;
					let consumedMandatoryItems = 0;
					// Scorro tutti gli id propedeutici (anche se mi aspetto ce ne sia solo uno)
					for (let i = 0; i < $scope.propedeuticReferenceIds.length; i++) {
						// Scorro tutte altre sezioni del progetto in cerca dell'id
						for (let k = 0; k < $scope.sectionList.length; k++) {
							if ($scope.sectionList[k].item.itemId == $scope.propedeuticReferenceIds[i]
								// 2017/06/13 fix sblocco sezioni per referenceId propedeutico (attualmente ne viene gestito solo 1, quello su t_item_child)
								&& $scope.propedeuticReferenceId == $scope.propedeuticReferenceIds[i]) {

								let myPropeduticSection: Item = $scope.sectionList[k].item;
								// Verifico se la sezione è stata marcata come consumata
								for (let f = 0; myPropeduticSection.engagements && f < myPropeduticSection.engagements.length; f++) {
									if (myPropeduticSection.engagements[f].eventName == LibraryApplicationData.constants.EVENT_ITEM_CONSUMED) {
										$scope.isItemDisabled = false;
										break;
									}
								}
								/*
								// Devo verificare che tra i suoi oggetti (figli) tutti quelli obbligatori siano consumati o certificati
								if (myPropeduticSection.itemChilds) {
									for (let w = 0; w < myPropeduticSection.itemChilds.length; w++) {
										let currentChild = myPropeduticSection.itemChilds[w];
										let isChildMandatory = !!currentChild.mandatory;
		
										if (currentChild.childObject && isChildMandatory) {
											mandatoryItems++;
		
											if (currentChild.childObject && currentChild.childObject.engagements && currentChild.childObject.engagements.length) {
												for (let f = 0; f < currentChild.childObject.engagements.length; f++) {
													if (currentChild.childObject.engagements[f].eventName == LibraryApplicationData.constants.EVENT_ITEM_CONSUMED) {
														consumedMandatoryItems++;
														break;
													}
												}
											}
										}
									}
								}
								*/
							}
						}
					}
					/*
					if (mandatoryItems == consumedMandatoryItems) {
						$scope.isItemDisabled = false;
					}
					*/
				}

				// Recupero le info degli itemChilds per i contatori degli stati
				// Oggetti della sezione
				if ($scope.item.itemChilds) {
					for (let z = 0; z < $scope.item.itemChilds.length; z++) {
						let currentChild = $scope.item.itemChilds[z];
						let isChildMandatory = !!currentChild.mandatory;

						if (currentChild.childObject) {
							if (isChildMandatory) {
								$scope.mandatoryItemChilds++;
							}

							if (currentChild.childObject && currentChild.childObject.engagements && currentChild.childObject.engagements.length) {
								for (let f = 0; f < currentChild.childObject.engagements.length; f++) {
									if (currentChild.childObject.engagements[f].eventName == LibraryApplicationData.constants.EVENT_ITEM_CONSUMED) {
										if (isChildMandatory) {
											$scope.consumedMandatorItemChilds++;
										}
										$scope.totalConsumedItemChilds++

										break;
									}
								}
							}
						}


						let blocked = false;
						let cert = false;
						let viewed = false;
						for (let k = 0; $scope.item.itemChilds[z].childObject && $scope.item.itemChilds[z].childObject.engagements && k < $scope.item.itemChilds[z].childObject.engagements.length; k++) {
							if ($scope.item.itemChilds[z].childObject.engagements[k].moduleName == LibraryApplicationData.constants.ITEM
								&& $scope.item.itemChilds[z].childObject.engagements[k].eventName == LibraryApplicationData.constants.EVENT_ITEM_CERTIFIED) {
								// Oggetto certificato
								cert = true;
							} else if ($scope.item.itemChilds[z].childObject.engagements[k].moduleName == LibraryApplicationData.constants.ITEM
								&& $scope.item.itemChilds[z].childObject.engagements[k].eventName == LibraryApplicationData.constants.EVENT_ITEM_OPEN) {
								// Oggetto visto
								viewed = true;
							}
						}

						// Se ha un propedeutico, verifico se è stato fatto o meno
						if ($scope.item.itemChilds[z].propedeuticReferenceId) {
							let isEnabled = false;
							for (let p = 0; p < $scope.item.itemChilds.length && !isEnabled; p++) {
								if ($scope.item.itemChilds[p].childObject && $scope.item.itemChilds[p].referenceId == $scope.item.itemChilds[z].propedeuticReferenceId && $scope.item.itemChilds[p].childObject.engagements && $scope.item.itemChilds[p].childObject.engagements.length) {
									for (let j = 0; j < $scope.item.itemChilds[p].childObject.engagements.length; j++) {
										if ($scope.item.itemChilds[p].childObject.engagements[j].moduleName == LibraryApplicationData.constants.ITEM
											&& $scope.item.itemChilds[p].childObject.engagements[j].eventName == LibraryApplicationData.constants.EVENT_ITEM_CONSUMED) {
											// Se l'oggetto associato è stato consumato, l'oggetto con il propedeuticReferenceId è sbloccato
											isEnabled = true;
											break;
										}
									}
								}
							}
							if (!isEnabled) {
								// Oggetto bloccato
								blocked = true;

							}
						}

						if (blocked) {
							$scope.totalDisabledObjInSection++;
						} else if (cert) {
							$scope.totalCertifiedObjInSection++;
						} else if (viewed) {
							$scope.totalViewedObjInSection++;
						} else {
							// Pallino vuoto, con nessuna azione ancora effettutata
							$scope.totalWithAnyActionObjInSection++;
						}
					}
				}

				// Data dell'evento EVENT_ITEM_STARTED
				let dateEventItemStarted: any = null;
				// Data dell'evento EVENT_ITEM_CONSUMED
				let dateEventItemConsumed: any = null;
				// Percentuale di avanzamento, cioè quella fornita dall'EVENT_ITEM_STARTED
				let percentageEventItemStarted: any = null;

				// Se possiede la scormRegistration, prendo da qui la percentuale di avanzamento
				let hasScormRegistration: boolean = $scope.fullItem && $scope.fullItem.scormRegistration ? true : false;
				if (hasScormRegistration) {
					$scope.percentageItem = $scope.fullItem.scormRegistration.score;
				}
				// Se possiede l'itemRegistration (quindi non è uno scorm ma è un oggetto Kaltura), prendo da qui la percentuale di avanzamento
				let hasItemRegistration: boolean = $scope.item && $scope.item.itemRegistration ? true : false;
				if (hasItemRegistration) {
					$scope.percentageItem = $scope.item.itemRegistration.currentPrecentage;
				}

				// Verifico gli engagement, cioè le azioni dell'utente sull'Item corrente
				for (let d = 0; d < $scope.item.engagements.length; d++) {
					let currentEngagement = $scope.item.engagements[d];

					if (currentEngagement.moduleName == LibraryApplicationData.constants.ITEM) {
						// Attualmente, la percentuale di avanzamento (o percentuale degli oggetti) è data dalla percentuale più recente fra quella dell'item consumed e quella dell'item started. Quindi la più recente, va mostrata. Di conseguenza, devo recuperarle entrambe e, poi, confrontarle
						if (currentEngagement.eventName == LibraryApplicationData.constants.EVENT_ITEM_CONSUMED) {
							// Sezione conclusa. Salvo la data in cui è stato creato tale engagement
							dateEventItemConsumed = currentEngagement.creationDate;
							$scope.isConsumed = true;
						}

						if (currentEngagement.eventName == LibraryApplicationData.constants.EVENT_ITEM_STARTED && currentEngagement.engagementDetails) {
							// Item iniziato. Salvo la data in cui è stato creato tale engagement e la percentuale
							dateEventItemStarted = currentEngagement.creationDate;
							for (let z = 0; z < currentEngagement.engagementDetails.length; z++) {
								let currentEngagementDetail = currentEngagement.engagementDetails[z];
								if (!hasItemRegistration && !hasScormRegistration && currentEngagementDetail.detailKey == LibraryApplicationData.constants.PERCENTAGE) {
									percentageEventItemStarted = currentEngagementDetail.detailValue;
									if (isNaN(percentageEventItemStarted)) {
										percentageEventItemStarted = "0";
									}
									break;
								}
							}
						}

						// Verifico se l'oggetto è già stato verificato
						if (currentEngagement.eventName == LibraryApplicationData.constants.EVENT_ITEM_CERTIFIED) {
							$scope.isItemAlreadyCertified = true;
						}
					}
				}

				// Se non ho una certificazione eseguita la cerco
				if (!$scope.isItemAlreadyCertified) {
					// Verifico se esiste una survey associata a questo oggetto
					SurveyService.getSurveys.query({
						referenceId: $scope.item.itemId
					}).$promise
						.then((data: SenecaResponse<Survey>) => {
							if (data.error) {
								// Dati non validi, quindi alzo l'errore
								toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
							} else {
								if (data.response && data.response.surveyStatus) {
									if (data.response.surveyStatus == 'STARTED') {
										// Survey iniziata
										$scope.isSurveyStarted = true;
									} else if (data.response.surveyStatus == 'VALID') {
										// Se lo status è valido, significa che ho una survey certificabile
										$scope.isSurveyCertificable = true;
									}
								}

								let mandatoryItems = 0;
								let consumedMandatoryItems = 0;

								// E verifico se posso effettuare la certificazione
								for (let k = 0; k < $scope.item.itemChilds.length; k++) {
									let currentObj = $scope.item.itemChilds[k];
									let isMandatory = !!currentObj.mandatory;
									if (currentObj.childObject && isMandatory) {
										mandatoryItems++;
										if (currentObj.childObject.engagements && currentObj.childObject.engagements.length) {
											for (let j = 0; j < currentObj.childObject.engagements.length; j++) {
												if (currentObj.childObject.engagements[j].eventName == LibraryApplicationData.constants.EVENT_ITEM_CONSUMED) {
													consumedMandatoryItems++;
													break;
												}
											}
										}
									}
								}
								if (mandatoryItems == consumedMandatoryItems) {
									$scope.isItemCertifiable = true;
								}
							}
						})
						.catch((error: any) => {
							// Non mostro la modale di errore se ho cancellato volutamente la richiesta
							if (!error || error.config.timeout.$$state.status !== 1) {
								// Verifico se è un problema di connettività
								let errorMessage: string = null;

								// Nuovo oggetto d'errore
								let newError: any = {
									severity: "danger"
								}

								if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
									// Problema di connettività
									errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
									newError.hideUnknown = true;
								} else {
									// Messaggio di errore generico
									errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
								}

								// Imposto il messaggio
								newError.message = errorMessage;

								// Lo aggiungo alla lista
								let errors: Array<any> = [];
								errors.push(newError);

								// E apro la modale
								this.$rootScope.$emit("showApplicationModalErrors", errors);
							}
						});
				}

				// Se ho sia l'engagement STARTED che CONSUMED, devo recuperare il più recente
				if (!hasScormRegistration && !hasItemRegistration) {
					if (dateEventItemStarted && dateEventItemConsumed) {
						let startedTime = new Date(dateEventItemStarted).getTime();
						let consumedTime = new Date(dateEventItemConsumed).getTime();
						if (startedTime > consumedTime) {
							// E' più recente l'evento started, quindi visualizzo a front-end la sua percentuale
							$scope.percentageItem = percentageEventItemStarted;
						} else {
							// Altrimenti la setto io al 100%
							$scope.percentageItem = "100";
						}
					} else if (dateEventItemStarted && !dateEventItemConsumed) {
						$scope.percentageItem = percentageEventItemStarted;
					} else if (!dateEventItemStarted && dateEventItemConsumed) {
						$scope.percentageItem = "100";
					} else {
						$scope.percentageItem = "0";
					}
				}
			}

			$scope.windowWidth = $window.innerWidth;
			// Cambio la classe del titolo in base alla sua lunghezza, così diminuendo il font-size evito che sfori
			if ($scope.item && $scope.item && $scope.item.title.length < 62) {
				$scope.item.titleClass = 'card-title-h3';
			} else if ($scope.item && $scope.item && $scope.item.title.length > 62 && $scope.item.title.length > 62) {
				$scope.item.titleClass = 'card-title-h2';
			}

			// Eseguo la verifica sul carattere anche al resize e al caricamento della pagina
			angular.element($window).bind('resize', () => {
				// Calcolo la larghezza della finestra
				$scope.windowWidth = $window.innerWidth;

				if ($scope.windowWidth < 680 && $scope.item && $scope.item && $scope.item.title.length < 62) {
					$scope.item.titleClass = 'card-title-h6';
				} else if ($scope.isWidthExceeded == true) {
					$scope.item.titleClass = 'card-title-h3';
				}

				if ($scope.windowWidth < 680 && $scope.item && $scope.item && $scope.item.title.length > 62 && $scope.item.title.length > 62) {
					$scope.item.titleClass = 'card-title-h5';
				} else if ($scope.isWidthExceeded == true) {
					$scope.item.titleClass = 'card-title-h2';
				}
			});
			// e devo controllare al caricamento della pagina
			angular.element(document).ready(() => {
				// Calcolo la larghezza della finestra
				$scope.windowWidth = $window.innerWidth;

				if ($scope.windowWidth < 680 && $scope.item && $scope.item && $scope.item.title.length < 62) {
					$scope.item.titleClass = 'card-title-h6';
				} else if ($scope.isWidthExceeded == true) {
					$scope.item.titleClass = 'card-title-h3';
				}

				if ($scope.windowWidth < 680 && $scope.item && $scope.item && $scope.item.title.length > 62 && $scope.item.title.length > 62) {
					$scope.item.titleClass = 'card-title-h5';
				} else if ($scope.isWidthExceeded == true) {
					$scope.item.titleClass = 'card-title-h2';
				}

				if (!$scope.isItemDisabled) {
					let elementDiv = document.getElementById('section-div-' + ($scope.item && $scope.item.itemId));
					if (elementDiv) {
						elementDiv.removeAttribute('role');
					}
				}
				for (let i = 0; i < $scope.item.itemChilds.length; i++) {
					let element = $scope.item.itemChilds[i];
					let elementDivChild = document.getElementById('div-container-child-' + (element && element.itemId));
					if (elementDivChild) {
						elementDivChild.removeAttribute('role');
					}
				}
			});
		}
	}
});